/* Assuming you have a container for the detailed card */
h1 {
  font-weight: 900;
  font-family: montserrat,arial,sans-serif;
  line-height: 1.1375;
  text-transform: uppercase;
  font-size: 2rem;
}

.detailed-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;

}

/* Left column (image) */
.left-column {
  flex: 1;
  padding: 20px;
}

.left-column img {
  max-width: 100%;
  height: auto;
  border-radius: 15%;
  /* border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Right column (text content) */
.right-column {
  flex: 1;
  padding: 20px;
  /* background-color: #f9f9f9; */
  /* border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.right-column h2 {
  font-size: 24px;
  margin: 0;
}

.right-column p {
  font-size: 16px;
  margin: 10px 0;
}

/* Social media icons */
.social-media-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-media-icons a {
  text-decoration: none;
  color: #333;
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-media-icons a:hover {
  color: #007bff;
}

.see-more{
  cursor: pointer;
  font-weight: bold;
  margin-left: 0.4em;
  color: #545454;
}
.Rating-Distribution-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
 padding: 1em 0.5em;
 min-width: 400px;

 
}

.Rating-Distribution-wrapper > *:first-child{
  font-weight: bold;

  width: 100%;
}
.p-rating-total{
font-size: 0.6rem;

}
.totalreview{
  font-weight: 700;
  font-size: 2rem;
  color: #545454;
  
}
.totalRating{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 1em 0 !important;
  color: #1E1E1E;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;

}
.totalRating > *{

  margin-left: 0;
  
}

.social-links{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none ; 
}
.social-links > * > *{
  font-size: 1.8em;
  color: #545454;
 
}
.social-links > * :focus{
  border: none;
}

.review-container{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin:1em 0;
  min-width: 400px
  
}


/* Media Query for smaller screens */
@media (max-width: 768px) {
  .detailed-card {
    flex-direction: column; /* Stack columns on smaller screens */
    height: auto;
  }

  .left-column,
  .right-column {
    flex: 1; /* Make both columns take full width on small screens */
  }
}


.candidate__banner {
  display: flex;
  position: relative; 
}

.candidate__banner:before {
  content: '';
  display: block;
  background: url(./../../../public/speech-bubble.svg) center no-repeat;
  background-size: contain;
  height: 100%;
  max-width: 80%;
  width: 100%;
  position: absolute;
  left: -100px;
  top: 0;
}

.candidate__banner .candidate__mask-flipped {
  width: 100%;
  position: relative;
  max-width: 80%;
}

.candidate__mask-flipped {
  margin-bottom: 0.9375rem;
  -webkit-mask-image: url(./../../../public/speech-bubble-flipped.svg);
  mask-image: url(./../../../public/speech-bubble-flipped.svg);
  mask-repeat: no-repeat;
}

.candidate__image {
  background-color: #f7f5f5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: block;
}
.sharebtn{
  position: absolute;
  right: 12%;
  top: 1%;
  font-size: 1rem;
  vertical-align: auto;
  overflow: unset;
  color: #545454;

}
.closecontainer{
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0.5em;
  left: 0.5em;
}
.closebtn{
  position: absolute;
  top: 0;
  left: 0;
  align-self: flex-start;
  margin-left: 0;
  margin-right: auto;
  margin-top: 0;
  justify-self: flex-start;
  font-size: 1rem;
  color: #545454;
}

.reviewsList{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
}
.reviewsList > *{
  padding: 1em !important;
}

@media screen and (max-width:700px){
  .social-links{
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #D9D9D9;
    max-width: 300px;
    width: 100%;
    padding: 2em 1em;
    border-radius: 5px;
    display: none !important;
    z-index: 2;

  }
} 
  

@media screen and (min-width:700px) {
  .review-container{
    min-width: 300px;
    width: 300px;
  }
  .social-links{
    display: flex ;
    max-width: 200px;
    width: 100%;
  }
  .sharebtn{
    display: none;
  }
  .closecontainer{
    display: none;
  }
}

@media screen and (max-width:450px) {
  .review-container{
    min-width: 100% ;
    width: 100%;
  }
  .sharebtn{
    right: 22%;
  }
}

@media screen and (max-width:370px) {
  .Rating-Distribution-wrapper{
    min-width: 100%;
  }
}
.show{
  display: flex !important;
}

.rightContainerReviewOverall{
  padding: 1em !important;
}
@media screen and (min-width:1600px) {
  
  .rightContainerReviewOverall{
    display: flex !important;
    flex-direction: row !important;
    gap: 1em;
    max-width: 60% !important;
    padding: 0.5em 1em 1em 4em !important;
  }
  .Rating-Distribution-wrapper > *:last-child{
    display: flex !important;
    flex-direction: column !important;
  }
  .Rating-Distribution-wrapper > *:first-child{
    font-size: 1.5rem;
  }
  .totalreview{
    font-size: 2.5rem;
  }
  .totalRating{
    max-width: 30% !important;
  }
  .p-rating-total{
    font-size: .9rem;
  }

  .reviewsList > *{
      max-width: 48%;
      

  }
}

@media screen and (min-width:2000px) {
  .rightContainerReviewOverall{
    width: 40%;
  }
}