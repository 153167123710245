/* Add this CSS to your stylesheet */
header {

  padding: 10px 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
 
}

/* Make the logo image responsive */
.logo img {
  max-width: 50%;
  height: auto;
}


.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px; /* Add spacing between links */
}

.nav-links li {
  font-size: 16px;
}

.nav-links a {
  text-decoration: none;
  color: #000;
  font-weight: bolder;
}

.nav-links a:hover {
  text-decoration: none;
  background-color: #6FF;
  padding: 5px; /* Add padding for spacing */
  margin: 5px; /* Add margin for spacing */
  transition: all 0.3s ease; /* Add a smooth transition effect */
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start; /* Align items to the start (left) */
  }

  .nav-links {
    margin-top: 10px; /* Add spacing between logo and links on small screens */
  }
}
