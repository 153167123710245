.footer {
  padding: 1.5rem 0;
  overflow: hidden;
}
.bg--black {
  background-color: #000;
}
.color--white {
  color: #fff;
}
.py-5 {
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
}
* {
  box-sizing: border-box;
}
footer {
  display: block;
}

ul {
  margin: 0px;
  padding: 0px;
}

footer li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: montserrat,arial,sans-serif;
  font-size: 16px;
  line-height: 1.25;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  color: #6ff;
  text-decoration: none;
}

footer h3 {
  color: #6ff;
  text-transform: uppercase;
  font-weight: 800;
}

.footer__social {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.d-inline-block {
  display: inline-block!important;
}

.footer__social li:first-child {
  margin-left: 0;
}
.footer__social li {
  display: inline-block;
  margin: 0 6px;
}


.logo-policritic{
  color: #6ff !important;
}


.footer-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-content > *:last-child{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  font-weight: bold;
}