.comments-form-container {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  /* justify-self: center;
  align-self: center; 
  */

}

.comments-form-container h2{
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
 
}
.comments-form {
  display: flex;
  flex-direction: column;

}

.comments-form h3 {
  font-weight: 600;
  font-size: 0.8rem;
  color: #3B3B3B;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group label {
  flex: 1;
  display: block;
  margin-right: 10px;
}

.checkbox-group input {
  margin-right: 5px;
}




/* In your CSS file or style block */
@media (max-width: 600px) {
  /* Adjust the styles for small screens here */
  .comments-form .form-group {
    margin-bottom: 10px;
  }
}

@media (min-width: 601px) {
  /* Adjust the styles for screens wider than 600px here */
  .comments-form .form-group {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:390px) {
  .comment-wrapper{
    min-width: 100% !important;
  }
}

@media screen and (min-width:1600px) {

  .comments-form-container h2{
    font-size: 1.5rem;
  }
  .comments-form-container h3{
    font-size: 1.2rem;
  }
}