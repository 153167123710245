.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3%;
  border-radius: 50px !important;
  position: relative;
}

.flex-container > div {
  flex: 1;
  min-width: 350px; /* Adjust the minimum width of each card */
  max-width: 400px; /* Adjust the maximum width of each card */
  border-radius: 10px;
  margin: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #eceaea;
}

.card-link {
  text-decoration: none;
  color: #333;
  font-family: montserrat,arial,sans-serif;
}

.card-link:hover {
  text-decoration: none;
  color: #686868;
  font-family: montserrat,arial,sans-serif;
}

.card p {
  font-weight: 600;
  font-family: montserrat,arial,sans-serif;
}

.card h3 {
  margin-top: 15px;
  font-weight: 700;
  font-family: montserrat,arial,sans-serif;
}

.politician-img-container{
  background-color: #fff;
  padding: 20px 40px;
}

.politician-mask{
  mask-image: url("./../../../public/speech-bubble.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  width: 100%;
  height: 250px;
}

.candidate-image:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.politician-image {
  background-color: #f7f5f5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block;
}


.politician-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.politician-info > div{
  align-self: center;
  justify-self: center;
}