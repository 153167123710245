/* Header.css */
.select-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 20px; /* Adjust the gap as needed */
  margin: 20px 20px;
}
.select-row > *{
  flex:1;
}

.card-filters input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
  height: 25px;
}

.card-filters button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.card-filters button:hover {
  background-color: #0056b3;
}

/* .card-list {
  background-color: lightblue;

} */
